<template>
  <form
    action
    data-vv-scope="inventorymovements-form"
    @submit.prevent="validateForm('inventorymovements-form')"
  >
    <v-card-text id="formMovement">
      <!--Contenido del formulario-->
      <v-container class="pa-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-radio-group
              row
              v-if="
                title == 'general' || title == 'view' || title == 'appointment'
              "
              v-model="type_product"
              class="pl-2"
              data-vv-name="gasto"
              :error-messages="errors.collect('inventorymovements-form.gasto')"
              data-vv-validate-on="blur"
              value="1"
              hide-details
              v-validate="'required'"
            >
              <v-radio color=" primary">
                <template v-slot:label
                  ><div style="margin-top: 6px">
                    {{ $t("inventorys.receipts_table") }}
                  </div>
                </template>
              </v-radio>
              <v-radio color="primary">
                <template v-slot:label
                  ><div style="margin-top: 6px">
                    {{ $t("inventorys.issues_table") }}
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6" class="py-0" v-if="title != 'view'">
            <label v-text="$tc('inventorys.product_cody')" />
            <v-select
              clearable
              v-model="inventory"
              outlined
              dense
              hide-details
              data-vv-name="product_cody"
              :error-messages="
                errors.collect('inventorymovements-form.product_cody')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              class="icon"
              single-line
              :items="items"
              return-object
              @change="fetchInventory"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.product_cody }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.product_cody }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0" v-if="title != 'view'">
            <label v-text="$tc('inventorys.description')" />
            <v-select
              clearable
              v-model="inventory"
              outlined
              dense
              data-vv-name="description"
              :error-messages="
                errors.collect('inventorymovements-form.description')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              class="icon"
              single-line
              :items="items"
              return-object
              @change="fetchInventory"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.description }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.description }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-2" v-if="title == 'view'">
            <label>{{ $t("inventorys.product_cody") }}</label>
            <v-text-field
              v-if="action == 'edit'"
              v-model="inventorymovement.inventory.product_cody"
              outlined
              dense
              disabled
              data-vv-name="product_cody"
              :error-messages="
                errors.collect('inventorymovements-form.product_cody')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
            <v-text-field
              v-else
              v-model="inventorymovement.product_cody"
              outlined
              dense
              disabled
              data-vv-name="product_cody"
              :error-messages="
                errors.collect('inventorymovements-form.product_cody')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-2" v-if="title == 'view'">
            <label>{{ $t("inventorys.description") }}</label>
            <v-text-field
              v-if="action == 'edit'"
              v-model="inventorymovement.inventory.description"
              outlined
              dense
              disabled
              data-vv-name="description"
              :error-messages="
                errors.collect('inventorymovements-form.description')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
            <v-text-field
              v-else
              v-model="inventorymovement.description"
              outlined
              dense
              disabled
              data-vv-name="description"
              :error-messages="
                errors.collect('inventorymovements-form.description')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-2">
            <label v-text="$t('expenses.pay_mode')" />
            <v-select
              clearable
              v-model="inventorymovement.pay_mode"
              outlined
              dense
              hide-details
              v-validate="'required'"
              data-vv-name="pay"
              class="icon"
              single-line
              :items="pay"
              :error-messages="errors.collect('inventorymovements-form.pay')"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.text }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.text }}
              </template>
            </v-select>
          </v-col>
          <v-col
            v-if="
              title == 'salidas' ||
              type_product == 1 ||
              (type_product == null && title != 'entradas')
            "
            cols="12"
            md="6"
            class="py-0 my-2"
          >
            <label v-text="$t('inventorys.type_use')" />
            <v-select
              clearable
              v-model="inventorymovement.type_product"
              outlined
              dense
              :disabled="type_product == null && title != 'salidas'"
              hide-details
              v-validate="'required'"
              data-vv-name="pay"
              class="icon"
              single-line
              :items="salida"
              :error-messages="errors.collect('inventorymovements-form.pay')"
            >
            </v-select>
          </v-col>
          <v-col
            v-else-if="title == 'entradas' || type_product == 0"
            cols="12"
            md="6"
            class="py-0 my-2"
          >
            <label v-text="$t('inventorys.type_product')" />

            <v-select
              clearable
              v-model="inventorymovement.type_product"
              outlined
              :disabled="type_product == null && title != 'entradas'"
              dense
              hide-details
              v-validate="'required'"
              data-vv-name="pay"
              class="icon"
              single-line
              :items="entrada"
              :error-messages="errors.collect('inventorymovements-form.pay')"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-2">
            <label>{{ $t("product.quantity") }}</label>

            <v-text-field
              v-model="inventorymovement.cantidad"
              outlined
              dense
              type="number"
              v-on:keypress="isNumber($event)"
              data-vv-name="cantidad"
              :error-messages="
                errors.collect('inventorymovements-form.cantidad')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-2">
            <label>{{ $t("designs.price") }}</label>
            <v-text-field
              v-model="inventorymovement.price"
              outlined
              type="number"
              v-on:keypress="isNumber($event)"
              dense
              suffix="€"
              data-vv-name="price"
              :error-messages="errors.collect('inventorymovements-form.price')"
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <label>{{ $t("inventorys.invoice_number") }}</label>
            <v-text-field
              v-model="inventorymovement.invoice_number"
              outlined
              dense
              data-vv-name="invoice_number"
              :error-messages="
                errors.collect('inventorymovements-form.invoice_number')
              "
              data-vv-validate-on="blur"
              v-validate="''"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="py-0 my-1">
            <label>{{ $t("expenses.data") }}</label>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              class="pointer"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  v-validate="'required'"
                  data-vv-name="data"
                  :error-messages="
                    errors.collect('inventorymovements-form.data')
                  "
                  :value="
                    inventorymovement.data
                      ? $d(new Date(inventorymovement.data), 'date2digits')
                      : ''
                  "
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="inventorymovement.data"
                color="primary"
                no-title
                scrollable
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="py-0 my-1" v-if="false">
            <label>{{ $t("inventorys.appointment") }}</label>

            <v-text-field
              v-model="inventorymovement.appointment_id"
              outlined
              dense
              data-vv-name="product_cody"
              :error-messages="
                errors.collect('inventorymovements-form.product_cody')
              "
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="cancel()"
        style="height: 25px; width: 100px"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >
      <v-btn
        type="submit"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        ><v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}</v-btn
      >
    </v-card-actions>
  </form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "InventorymovementsForm",
  props: [
    "inventorymovement",
    "action",
    "type",
    "title",
    "showAddButton",
    "productId",
    "items",
  ],
  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  data() {
    return {
      inventory: undefined,
      pay: [
        {
          text: this.$t("expenses.cash"),
          value: "cash",
        },
        {
          text: this.$t("expenses.card"),
          value: "card",
        },
      ],
      entrada: [
        {
          text: this.$t("inventorys.merchandise"),
          value: "mercaderia",
        },
        {
          text: this.$t("inventorys.material_raw"),
          value: "mercaderia prima",
        },
      ],
      salida: [
        {
          text: this.$t("inventorys.sale"),
          value: "venta",
        },
        {
          text: this.$t("inventorys.consumption"),
          value: "consumo",
        },
      ],

      menu: false,
      menuUntil: false,
      show: false,
      type_product: null,
    };
  },
  mounted() {},
  methods: {
    /*fetchAll() {
      this.getAll().then((data) => {
        //console.log(data);
        this.items = data.data;
      });
    },*/
    changeType(item) {
      this.type_product = item;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    //mirar bien el actions, el que he creado yo petaba o no funcionaba correctamente, preguntar por esta parte
    ...mapActions("inventorymovements", [
      "addInventorymovements",
      "editInventorymovements",
    ]),
    ...mapActions("inventory", ["getInventory"]),
    fetchInventory() {
      this.inventorymovement.price = this.inventory.product_price;
    },
    async validateForm(scope) {
      this.errors.clear();
      let form_val = await this.$validator.validateAll(scope);
      //console.log(this.inventory);
      if (this.inventory) this.inventorymovement.product_id = this.inventory.id;
      if (this.productId) {
        this.inventorymovement.product_id = this.productId;
      }
      //console.log(this.inventorymovement);
      this.inventorymovement.type = this.type_product;
      if (this.title == "entradas") {
        this.inventorymovement.type = 0;
      } else if (this.title == "salidas") {
        this.inventorymovement.type = 1;
      }
      //console.log(this.inventorymovement);
      if (form_val) {
        if (
          this.title == "salidas" &&
          this.inventorymovement.type_product !== "venta"
        )
          this.$confirm(this.$t("inventory_consume")).then(() => {
            this.actionDo();
          });
        else this.actionDo();
      }
      return false;
    },
    actionDo() {
      if (this.action === "save") {
        this.addInventorymovements(this.inventorymovement).then((data) => {
          if (data.success) {
            this.$emit("refreshTable");
            this.$alert(this.$t("transactionADD_success"), "", "");
          } else {
            this.$alert(this.$t(data.data), "", "");
          }
        });
      } else {
        this.editInventorymovements(this.inventorymovement).then((data) => {
          if (data.success) {
            //console.log("edit");
            this.$emit("refreshTable");
            this.$alert(this.$t("transactionEdit_success"), "", "");
          } else {
            this.action = "edit";
            this.$alert(this.$t(data.data), "", "");
          }
        });
        this.dialog = false;
        //////console.log("validated");
      }
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="sass">
.v-input--selection-controls.v-input .v-label
  line-height: 13px
.v-input--selection-controls .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before
  background: var(--v-primary-base) !important
.v-input--selection-controls
  margin: 0px
  padding: 0px
.theme--dark.v-input--switch .v-input--switch__thumb
  color: var(--v-primary-base) !important
#formMovement
  .v-input__control, .row .v-btn
    height: 30px !important
    margin-top: 5px

  label
    display: block
    height: 20px !important
  .v-input--selection-controls__input
    margin-bottom: -6px !important
  .close .v-btn
    top: 0
    right: 0px
    position: absolute
    .v-icon
      color: var(--v-primary-base)
      position: absolute

#InventorymovementsForm
  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important
  .v-application--is-ltr .v-text-field__suffix
    padding-top: 4px
  .icon
    .theme--dark.v-icon
      color: var(--v-primary-base) !important
</style> 